<script lang="ts">
import { defineComponent } from 'vue'

/**
 * AppInformationModal
 */
export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: '',
    },
    modalClass: {
      type: String,
      default: '',
    },
    defaultSlotClass: {
      type: String,
      default: '',
    },
    triggerSlotClass: {
      type: String,
      default: '',
    },
    showFaqButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
})
</script>

<template>
  <AppModal
    :id="`information-modal-${id}`"
    :show-close-button="false"
    :class="modalClass"
  >
    <template #button="{ openModal }">
      <p
        @click="openModal"
        class="flex items-center gap-1 text-xs underline hover:cursor-pointer hover:no-underline"
        :class="triggerSlotClass"
      >
        <slot name="text" /><AppIcon
          :icon="Icons.Information"
          size="xs"
          :class="iconClass"
        />
      </p>
    </template>

    <template #default="{ closeModal }">
      <div class="flex flex-col gap-4 p-5" :class="defaultSlotClass">
        <slot :close-modal />

        <RouterLink :to="{ name: 'terms-and-conditions' }" class="link text-sm">
          See Full Terms & Conditions
        </RouterLink>

        <div class="flex gap-2">
          <AppButton
            v-if="showFaqButton"
            class="button-secondary button-secondary-light w-full flex-1 whitespace-nowrap text-sm !font-normal"
            :route="{ name: 'faq' }"
            target="_blank"
            title="Go to FAQs"
            icon=""
            >Go to FAQs</AppButton
          >
          <AppButton
            class="button-primary w-full flex-1 text-sm !font-normal"
            @click="closeModal"
            title="Close"
            icon=""
            >Close</AppButton
          >
        </div>
      </div>
    </template>
  </AppModal>
</template>
